<template>
    <div>
        <footer id="footer" class="footer">
        <div class="container-fluid">
            <div class="row">
            <div class="col-sm-7">
                <p class="footer-left">
                <img class='logo-footer mr-1' src="../../assets/images/logo-innovare-dark.png"><sup>&reg;</sup>
                2021 -
                {{ getFullYear() }}
                - Todos os direitos reservados.
                </p>
            </div>
            <div class="col-sm-5">
                <p class="footer-right">
                Desenvolvido por
                <a href="https://www.innovareti.com.br/" target="_blank">
                    <img class='logo-footer' src="../../assets/images/logo-innovare-dark.png">
                </a>
                </p>
            </div>
            </div>
        </div>
        </footer>
    </div>
</template>

<script>
export default {
  methods: {
      getFullYear(){
        return new Date().getFullYear()
      }
  },
};
</script>


<style scoped>

</style>
