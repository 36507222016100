<template>
  <div id="bgblack">
    <div class="wrapper">
      <!-- Content -->
      <div id="content" class="content">
        <router-view></router-view>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from './Footer'
export default {
  name: "app",
  props: {
    source: String
  },
  components: {
    Footer
  },
  mounted(){
    $("#bgblack").css("height", $(window).height());
  }
};
</script>

<style scoped>

</style>

